import SHZT from './sn-table-column-shzt';
const selfData = {
    loading: false,
    snTable: {
        table: {
            data: [],
            rowKey: 'ID',
        },
        columns: {
            // 选择、序号
            columnsOperate: [
                {
                    isShow: true,
                    type: 'selection',
                    width: 35
                },
            ],
            // 数据列
            columnsData: [
                {
                    prop: 'XMMC',
                    label: '项目名称',
                    minWidth: '',
                },
                // {
                //     prop: 'XJXMBH',
                //     label: '民居建设编号',
                //     minWidth: '',
                // }, 
                {
                    prop: 'YCCZ',
                    label: '村',
                    minWidth: '',
                }, {
                    prop: 'YCYS',
                    label: '村民小组',
                    minWidth: '',
                },

                {
                    prop: 'HZXM',
                    label: '户主姓名',
                    minWidth: '',
                },
                // {
                //     prop: 'XMSM',
                //     label: '备注',
                //     minWidth: '',
                // },

                {
                    prop: 'KJ_DESC',
                    label: '框架',
                    minWidth: '',
                    transList: {
                        "未开始": "未开始",
                        '施工中': "施工中",
                        '完成': "完成",
                    },
                    colorList: {
                        "未开始": "rgb(255, 204, 102)",
                        '施工中': "rgb(32, 160, 255)",
                        '完成': "rgb(46, 195, 146)",
                    },

                }, {
                    prop: 'FD_DESC',
                    label: '封顶',
                    minWidth: '',
                    transList: {
                        "未开始": "未开始",
                        '施工中': "施工中",
                        '完成': "完成",
                    },
                    colorList: {
                        "未开始": "rgb(255, 204, 102)",
                        '施工中': "rgb(32, 160, 255)",
                        '完成': "rgb(46, 195, 146)",
                    },
                }, {
                    prop: 'QQ_DESC',
                    label: '砌墙',
                    minWidth: '',
                    transList: {
                        "未开始": "未开始",
                        '施工中': "施工中",
                        '完成': "完成",
                    },
                    colorList: {
                        "未开始": "rgb(255, 204, 102)",
                        '施工中': "rgb(32, 160, 255)",
                        '完成': "rgb(46, 195, 146)",
                    },
                },

                {
                    prop: 'SNZX_DESC',
                    label: '室内装修',
                    minWidth: '',
                    transList: {
                        "未开始": "未开始",
                        '施工中': "施工中",
                        '完成': "完成",
                    },
                    colorList: {
                        "未开始": "rgb(255, 204, 102)",
                        '施工中': "rgb(32, 160, 255)",
                        '完成': "rgb(46, 195, 146)",
                    },
                }, {
                    prop: 'SWZX_DESC',
                    label: '室外装修',
                    minWidth: '',
                    transList: {
                        "未开始": "未开始",
                        '施工中': "施工中",
                        '完成': "完成",
                    },
                    colorList: {
                        "未开始": "rgb(255, 204, 102)",
                        '施工中': "rgb(32, 160, 255)",
                        '完成': "rgb(46, 195, 146)",
                    },
                }, {
                    prop: 'JG_DESC',
                    label: '竣工',
                    minWidth: '',
                    transList: {
                        "是": "是",
                        '否': "否",
                    },
                    colorList: {
                        "是": "rgb(46, 195, 146)",
                        '否': "rgb(255, 204, 102)",
                    },
                },

                {
                    prop: 'XZMS',
                    label: '现状描述',
                    minWidth: '',
                },
                {
                    prop: 'CZWT',
                    label: '存在问题',
                    minWidth: '',
                },
                {
                    prop: 'SFAYSPFASS_DESC',
                    label: '是否按已审批方案实施',
                    minWidth: '',
                    transList: {
                        "是": "是",
                        '否': "否",
                    },
                    colorList: {
                        "是": "rgb(46, 195, 146)",
                        '否': "rgb(255, 204, 102)",
                    },
                },

                {
                    prop: 'LJ',
                    label: '照片',
                    showFileBtn: true
                },
                {
                    prop: 'CJSJ',
                    label: '采集时间',
                    minWidth: '',
                },
                SHZT,
            ],
            // 操作列
            columnsBtn: {
                buttonData: []
            }
        },
    },
    snSearch: {
        inputData: [{
            label: '关键字',
            placeholder: "请输入关键字",
            value: 'keyword',
            operateType: 'search',
            isShow: true,
            default: ""
        }],
    },
    snButton: {
        buttonData: [
            // {
            //     isShow: false,
            //     btnType: 'button',
            //     operateType: 'buttonNew',
            //     showMethod: 'showNewBtn',
            //     name: '新建',
            //     round: true,
            //     backColor: '#28ccd9',
            // },
            {
                btnType: 'button',
                operateType: 'buttonExport',
                showMethod: 'showExportBtn',
                name: '导出',
                round: true,
                backColor: '#ffcc66',
                color: '#fff'
            }
        ]
    }
};
export default selfData;